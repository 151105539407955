import { render, staticRenderFns } from "./TeamSettings.vue?vue&type=template&id=c6450f72&scoped=true&"
import script from "./TeamSettings.vue?vue&type=script&lang=js&"
export * from "./TeamSettings.vue?vue&type=script&lang=js&"
import style0 from "./TeamSettings.vue?vue&type=style&index=0&id=c6450f72&prod&lang=scss&scoped=true&"
import style1 from "./TeamSettings.vue?vue&type=style&index=1&id=c6450f72&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c6450f72",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFadeTransition } from 'vuetify/lib/components/transitions';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
installComponents(component, {VContainer,VDivider,VFadeTransition,VIcon,VList,VListItem,VListItemAction,VListItemContent,VListItemTitle,VNavigationDrawer})
